body {
  margin: 0;
  padding: 0;
  font-family: 'MuseoSans-500';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: lightgray;
}

html, body, #publismart {
  width: 1080px;
  height: 1920px;
  margin: 0 auto;
}

* {
  user-select: none;
}

::-webkit-scrollbar {
  width: 0px;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}

@font-face {font-family: 'MuseoSans-700';src: url('./fonts/3A0626_0_0.eot');src: url('./fonts/3A0626_0_0.eot?#iefix') format('embedded-opentype'),url('./fonts/3A0626_0_0.woff2') format('woff2'),url('./fonts/3A0626_0_0.woff') format('woff'),url('./fonts/3A0626_0_0.ttf') format('truetype');}
@font-face {font-family: 'MuseoSans-300';src: url('./fonts/3A0626_1_0.eot');src: url('./fonts/3A0626_1_0.eot?#iefix') format('embedded-opentype'),url('./fonts/3A0626_1_0.woff2') format('woff2'),url('./fonts/3A0626_1_0.woff') format('woff'),url('./fonts/3A0626_1_0.ttf') format('truetype');}
@font-face {font-family: 'MuseoSans-900Italic';src: url('./fonts/3A0626_2_0.eot');src: url('./fonts/3A0626_2_0.eot?#iefix') format('embedded-opentype'),url('./fonts/3A0626_2_0.woff2') format('woff2'),url('./fonts/3A0626_2_0.woff') format('woff'),url('./fonts/3A0626_2_0.ttf') format('truetype');}
@font-face {font-family: 'MuseoSans-500';src: url('./fonts/3A0626_3_0.eot');src: url('./fonts/3A0626_3_0.eot?#iefix') format('embedded-opentype'),url('./fonts/3A0626_3_0.woff2') format('woff2'),url('./fonts/3A0626_3_0.woff') format('woff'),url('./fonts/3A0626_3_0.ttf') format('truetype');}
@font-face {font-family: 'MuseoSans-100';src: url('./fonts/3A0626_4_0.eot');src: url('./fonts/3A0626_4_0.eot?#iefix') format('embedded-opentype'),url('./fonts/3A0626_4_0.woff2') format('woff2'),url('./fonts/3A0626_4_0.woff') format('woff'),url('./fonts/3A0626_4_0.ttf') format('truetype');}
@font-face {font-family: 'MuseoSans-700Italic';src: url('./fonts/3A0626_5_0.eot');src: url('./fonts/3A0626_5_0.eot?#iefix') format('embedded-opentype'),url('./fonts/3A0626_5_0.woff2') format('woff2'),url('./fonts/3A0626_5_0.woff') format('woff'),url('./fonts/3A0626_5_0.ttf') format('truetype');}
@font-face {font-family: 'MuseoSans-300Italic';src: url('./fonts/3A0626_6_0.eot');src: url('./fonts/3A0626_6_0.eot?#iefix') format('embedded-opentype'),url('./fonts/3A0626_6_0.woff2') format('woff2'),url('./fonts/3A0626_6_0.woff') format('woff'),url('./fonts/3A0626_6_0.ttf') format('truetype');}
@font-face {font-family: 'MuseoSans-100Italic';src: url('./fonts/3A0626_7_0.eot');src: url('./fonts/3A0626_7_0.eot?#iefix') format('embedded-opentype'),url('./fonts/3A0626_7_0.woff2') format('woff2'),url('./fonts/3A0626_7_0.woff') format('woff'),url('./fonts/3A0626_7_0.ttf') format('truetype');}
@font-face {font-family: 'MuseoSans-500Italic';src: url('./fonts/3A0626_8_0.eot');src: url('./fonts/3A0626_8_0.eot?#iefix') format('embedded-opentype'),url('./fonts/3A0626_8_0.woff2') format('woff2'),url('./fonts/3A0626_8_0.woff') format('woff'),url('./fonts/3A0626_8_0.ttf') format('truetype');}
@font-face {font-family: 'MuseoSans-900';src: url('./fonts/3A0626_9_0.eot');src: url('./fonts/3A0626_9_0.eot?#iefix') format('embedded-opentype'),url('./fonts/3A0626_9_0.woff2') format('woff2'),url('./fonts/3A0626_9_0.woff') format('woff'),url('./fonts/3A0626_9_0.ttf') format('truetype');}
 
